import { bookingConstants } from './types';
import axios from 'axios';
import { toast } from 'react-toastify';
import api, { apiv2 } from '../variables/api';

// GET Request to fetch all Bookings
export const getBookings = (page = 0, size = 20, sortBy = [], filters = []) => {
  const request = () => {
    return { type: bookingConstants.GET_BOOKING_REQUEST };
  };
  const success = (bookings, totalPages) => {
    return { type: bookingConstants.GET_BOOKING_SUCCESS, bookings, totalPages };
  };
  const failure = (error) => {
    return { type: bookingConstants.GET_BOOKING_FAILURE, error };
  };
  return async (dispatch) => {
    dispatch(request());

    try {
      const tempSortBy = {
        jobDate: sortBy.some((s) => s.id === 'jobDate')
          ? sortBy.find((s) => s.id === 'jobDate')?.desc
            ? -1
            : 1
          : 0,
        eventID: sortBy.some((s) => s.id === 'eventID')
          ? sortBy.find((s) => s.id === 'eventID')?.desc
            ? -1
            : 1
          : 0,
        eventName: sortBy.some((s) => s.id === 'eventName')
          ? sortBy.find((s) => s.id === 'eventName')?.desc
            ? -1
            : 1
          : 0,
        employeeName: sortBy.some((s) => s.id === 'employeeName')
          ? sortBy.find((s) => s.id === 'employeeName')?.desc
            ? -1
            : 1
          : 0,
        employeeGender: sortBy.some((s) => s.id === 'employeeGender')
          ? sortBy.find((s) => s.id === 'employeeGender')?.desc
            ? -1
            : 1
          : 0,
          hourBooked: sortBy.some((s) => s.id === 'hourBooked')
          ? sortBy.find((s) => s.id === 'hourBooked')?.desc
            ? -1
            : 1
          : 0,
        price: sortBy.some((s) => s.id === 'price')
          ? sortBy.find((s) => s.id === 'price')?.desc
            ? -1
            : 1
          : 0,
        contractSignedDate: sortBy.some((s) => s.id === 'contractSignedDate')
          ? sortBy.find((s) => s.id === 'contractSignedDate')?.desc
            ? -1
            : 1
          : 0,
      };

      const tempFilters = {
        jobDate: filters.find((s) => s.id === 'jobDate')?.value ?? '',
        eventID: filters.find((s) => s.id === 'eventID')?.value ?? '',
        eventName: filters.find((s) => s.id === 'eventName')?.value ?? '',
        employeeName: filters.find((s) => s.id === 'employeeName')?.value ?? '',
        employeeGender:
          filters.find((s) => s.id === 'employeeGender')?.value ?? '',
        hourBooked: filters.find((s) => s.id === 'hourBooked')?.value ?? '',
        price: filters.find((s) => s.id === 'price')?.value ?? '',
        contractSignedDate:
          filters.find((s) => s.id === 'contractSignedDate')?.value ?? '',
      };

      const data = {
        page: page,
        size: size,
        sortBy: tempSortBy,
        filters: tempFilters,
      };
      const bookingsData = await apiv2.post(`/bookings`, {
        ...data,
      });
      dispatch(
        success(
          bookingsData.data.data.bookedEvents,
          bookingsData.data.data.totalPages
        )
      );
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure('Something went wrong!'));
        toast.error('Something went wrong!');
      }
    }
  };
};

export const getTimeReports = (
  page = 0,
  size = 20,
  sortBy = [],
  filters = []
) => {
  const request = () => {
    return { type: bookingConstants.GET_TIME_REPORTS_REQUEST };
  };
  const success = (jobPositions, totalPages) => {
    return {
      type: bookingConstants.GET_TIME_REPORTS_SUCCESS,
      jobPositions,
      totalPages,
    };
  };
  const failure = (error) => {
    return { type: bookingConstants.GET_TIME_REPORTS_FAILURE, error };
  };
  return async (dispatch) => {
    dispatch(request());
    try {
      const tempSortBy = {
        jobDate: sortBy.some((s) => s.id === 'jobDate')
          ? sortBy.find((s) => s.id === 'jobDate')?.desc
            ? -1
            : 1
          : 0,
        eventID: sortBy.some((s) => s.id === 'eventID')
          ? sortBy.find((s) => s.id === 'eventID')?.desc
            ? -1
            : 1
          : 0,
        eventName: sortBy.some((s) => s.id === 'eventName')
          ? sortBy.find((s) => s.id === 'eventName')?.desc
            ? -1
            : 1
          : 0,
        jobPosition: sortBy.some((s) => s.id === 'jobPosition')
          ? sortBy.find((s) => s.id === 'jobPosition')?.desc
            ? -1
            : 1
          : 0,
        employeeName: sortBy.some((s) => s.id === 'employeeName')
          ? sortBy.find((s) => s.id === 'employeeName')?.desc
            ? -1
            : 1
          : 0,
        hourBooked: sortBy.some((s) => s.id === 'hourBooked')
          ? sortBy.find((s) => s.id === 'hourBooked')?.desc
            ? -1
            : 1
          : 0,
        breakTime: sortBy.some((s) => s.id === 'breakTime')
          ? sortBy.find((s) => s.id === 'breakTime')?.desc
            ? -1
            : 1
          : 0,
        jobStartTime: sortBy.some((s) => s.id === 'jobStartTime')
          ? sortBy.find((s) => s.id === 'jobStartTime')?.desc
            ? -1
            : 1
          : 0,
        jobEndTime: sortBy.some((s) => s.id === 'jobEndTime')
          ? sortBy.find((s) => s.id === 'jobEndTime')?.desc
            ? -1
            : 1
          : 0,
        employerName: sortBy.some((s) => s.id === 'employerName')
          ? sortBy.find((s) => s.id === 'employerName')?.desc
            ? -1
            : 1
          : 0,
      };
      const tempFilters = {
        jobDate: filters.find((s) => s.id === 'jobDate')?.value ?? '',
        eventID: filters.find((s) => s.id === 'eventID')?.value ?? '',
        eventName: filters.find((s) => s.id === 'eventName')?.value ?? '',
        jobPosition: filters.find((s) => s.id === 'jobPosition')?.value ?? '',
        employeeName: filters.find((s) => s.id === 'employeeName')?.value ?? '',
        hourBooked: filters.find((s) => s.id === 'hourBooked')?.value ?? '',
        breakTime: filters.find((s) => s.id === 'breakTime')?.value ?? '',
        jobStartTime: filters.find((s) => s.id === 'jobStartTime')?.value ?? '',
        jobEndTime: filters.find((s) => s.id === 'jobEndTime')?.value ?? '',
        employerName: filters.find((s) => s.id === 'employerName')?.value ?? '',
      };
      const data = {
        page: page,
        size: size,
        sortBy: tempSortBy,
        filters: tempFilters,
      };
      const response = await apiv2.post(`/time-reports`, {
        ...data,
      });
      dispatch(
        success(response.data.data.bookedEvents, response.data.data.totalPages)
      );
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure('Something went wrong!'));
        toast.error('Something went wrong!');
      }
    }
  };
};
